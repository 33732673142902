<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas d-flex" id="sidebar">
      <VuePerfectScrollbar class="app-sidebar-scroll" v-once>
        <ul class="nav pt-1 pb-0">
          <li class="nav-item" v-on:click="collapseAll">
            <router-link class="nav-link" to="/">
              <i class="mdi mdi-home menu-icon"></i>
              <span class="menu-title">Dashboard</span>
            </router-link>
          </li>
          <li class="nav-item">
            <span class="nav-link" v-b-toggle="'ui-basic'">
              <i class="mdi  mdi mdi-file-tree menu-icon"></i>
              <span class="menu-title">Catalog </span>
              <i class="menu-arrow"></i>
            </span>
            <b-collapse accordion="sidebar-accordion" id="ui-basic">
              <ul class="nav flex-column sub-menu">
                <!-- <button @click="close">Close</button> -->
                <li class="nav-item">
                  <router-link class="nav-link" to="/catalog/view-category">Category</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/catalog/stores">Stores</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/catalog/view-brand">Brand</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/catalog/tag">Tags</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/catalog/products">Product</router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-item" v-on:click="collapseAll">
            <router-link class="nav-link" to="/content-page/content-page">
              <i class="mdi mdi-newspaper menu-icon"></i>
              <span class="menu-title">Content </span>
            </router-link>
          </li>

          <li class="nav-item">
            <span class="nav-link" v-b-toggle="'user-page-dropdown123'">
              <i class=" mdi mdi-cart-outline menu-icon"></i>
              <span class="menu-title">Orders</span>
              <i class="menu-arrow"></i>
            </span>
            <b-collapse accordion="sidebar-accordion" id="user-page-dropdown123">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <router-link class="nav-link" to="/order/orders">All Orders</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/order/new-order">Add Offline Order</router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-item">
            <span class="nav-link" v-b-toggle="'ui-users'">
              <i class="mdi  mdi mdi-file-tree menu-icon"></i>
              <span class="menu-title">User Settings</span>
              <i class="menu-arrow"></i>
            </span>
            <b-collapse accordion="sidebar-accordion" id="ui-users">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <router-link class="nav-link" to="/roles/roles">Users Roles</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/users/users">Users</router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-item">
            <span class="nav-link" v-b-toggle="'ui-promotions'">
              <i class="mdi  mdi mdi-file-tree menu-icon"></i>
              <span class="menu-title">Promotions</span>
              <i class="menu-arrow"></i>
            </span>
            <b-collapse accordion="sidebar-accordion" id="ui-promotions">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <router-link class="nav-link" to="/promo-codes/promo-code-list">Promo Codes</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/offer-packages/offer-list">Offer Packages</router-link>
                </li>
              </ul>
            </b-collapse>
          </li>

          <li class="nav-item">
            <span class="nav-link" v-b-toggle="'user-page-dropdown'">
              <i class="mdi mdi-settings menu-icon"></i>
              <span class="menu-title">Settings</span>
              <i class="menu-arrow"></i>
            </span>
            <b-collapse id="user-page-dropdown" accordion="sidebar-accordion">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <span
                    class="nav-link aaa"
                    v-b-toggle="'user-page-dropdown15'"
                  >
                    <span class="menu-title"
                      >Invoice Settings &nbsp; &nbsp;</span
                    >
                    <i class="menu-arrow"></i>
                  </span>
                  <b-collapse
                    accordion="sidebar-accordion1"
                    id="user-page-dropdown15"
                  >
                    <ul class="nav flex-column sub-menu">
                      <li class="nav-item">
                        <router-link
                          class="nav-link sub"
                          to="/settings/invoice-list"
                          >Invoice List</router-link
                        >
                      </li>
                    </ul>
                  </b-collapse>
                </li>
                <li class="nav-item">
                  <span
                    class="nav-link aaa"
                    v-b-toggle="'user-page-dropdown111'"
                  >
                    <span class="menu-title"
                      >Payment Settings &nbsp; &nbsp;</span
                    >
                    <i class="menu-arrow"></i>
                  </span>
                  <b-collapse
                    accordion="sidebar-accordion1"
                    id="user-page-dropdown111"
                  >
                    <ul class="nav flex-column sub-menu">
                      <li class="nav-item">
                        <router-link
                          class="nav-link sub"
                          to="/settings/payment-list"
                          >Payment Modes</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="nav-link sub"
                          to="/settings/card-methods"
                          >Card Methods</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="nav-link sub"
                          to="/settings/config-settings"
                          >Config Settings</router-link
                        >
                      </li>

                      <!-- <li class="nav-item">
                        <router-link class="nav-link sub" to="/settings/paypal-settings"
                          > PayPal Settings</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link class="nav-link sub" to="/settings/stripe-settings"
                          > Stripe Settings</router-link
                        >
                      </li> -->
                    </ul>
                  </b-collapse>
                </li>
                <li class="nav-item">
                  <span
                    class="nav-link aaa"
                    v-b-toggle="'user-page-dropdown11'"
                  >
                    <span class="menu-title"
                      >Shipping Settings &nbsp; &nbsp;</span
                    >
                    <i class="menu-arrow"></i>
                  </span>
                  <b-collapse
                    accordion="sidebar-accordion1"
                    id="user-page-dropdown11"
                  >
                    <ul class="nav flex-column sub-menu">
                      <li class="nav-item">
                        <router-link
                          class="nav-link sub"
                          to="/settings/shipping-list"
                          >Shipping List</router-link
                        >
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="nav-link sub"
                          to="/settings/flat-rate"
                          >Flat Rate</router-link
                        >
                      </li>
                    </ul>
                  </b-collapse>
                </li>
                <li class="nav-item">
                  <span class="nav-link aaa" v-b-toggle="'media-dropdown'">
                    <span class="menu-title"
                      >Media Settings &nbsp;&nbsp; &nbsp;</span
                    >
                    <i class="menu-arrow"></i>
                  </span>
                  <b-collapse
                    accordion="sidebar-accordion1"
                    id="media-dropdown"
                  >
                    <ul class="nav flex-column sub-menu">
                      <li class="nav-item">
                        <router-link
                          class="nav-link sub"
                          to="/media/media-library"
                          >Media Library
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="nav-link sub"
                          to="/media/update-thumbnail"
                          >Thumbnail Update
                        </router-link>
                      </li>

                      <li class="nav-item">
                        <router-link
                          class="nav-link sub"
                          to="/media/media-settings"
                          >Media Settings
                        </router-link>
                      </li>
                    </ul>
                  </b-collapse>
                </li>
                <li class="nav-item">
                  <span class="nav-link aaa" v-b-toggle="'website-dropdown'">
                    <span class="menu-title"
                      >Website Settings &nbsp;&nbsp; &nbsp;</span
                    >
                    <i class="menu-arrow"></i>
                  </span>
                  <b-collapse
                    accordion="sidebar-accordion1"
                    id="website-dropdown"
                  >
                    <ul class="nav flex-column sub-menu">
                      <li class="nav-item">
                        <router-link
                          class="nav-link sub"
                          to="/website-management/banners"
                          >Banners Management
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="nav-link sub"
                          to="/website-management/menu"
                          >Menu Management
                        </router-link>
                      </li>

                      <li class="nav-item">
                        <router-link
                          class="nav-link sub"
                          to="/website-management/footer"
                          >Footer Management
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="nav-link sub"
                          to="/website-management/seo"
                          >Seo Management
                        </router-link>
                      </li>
                      <li class="nav-item">
                        <router-link
                          class="nav-link sub"
                          to="/website-management/theme"
                          >Theme Management
                        </router-link>
                      </li>
                    </ul>
                  </b-collapse>
                </li>
              </ul>
            </b-collapse>
          </li>


          <!-- <li class="nav-item">
            <span class="nav-link" v-b-toggle="'user-page-dropdown'">
              <i class="mdi mdi-settings menu-icon"></i>
              <span class="menu-title">Settings</span>
              <i class="menu-arrow"></i>
            </span>
            <b-collapse id="user-page-dropdown" accordion="sidebar-accordion">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item pb-2 mb-2 ">
                  <h5 class="menu-title  pt-3 mb-0">Invoice Settings</h5>
                  <div><router-link class="nav-link sub" to="/settings/invoice-list">Invoice List</router-link></div>
                </li>

                <li class="nav-item pb-2 mb-2 ">
                  <h5 class="menu-title  pt-1 mb-0">Payment Settings</h5>
                  <div><router-link class="nav-link sub" to="/settings/payment-list">Payment Modes</router-link></div>
                  <div><router-link class="nav-link sub" to="/settings/card-methods">Card Methods</router-link></div>
                  <div><router-link class="nav-link sub" to="/settings/config-settings">Config Settings</router-link>
                  </div>
                </li>

                <li class="nav-item pb-2 mb-2 ">
                  <h5 class="menu-title  pt-1 mb-0">Shipping Settings</h5>
                  <div><router-link class="nav-link sub" to="/settings/shipping-list">Shipping List</router-link></div>
                  <div><router-link class="nav-link sub" to="/settings/flat-rate">Flat Rate</router-link></div>
                </li>

                <li class="nav-item pb-2 mb-2 ">
                  <h5 class="menu-title  pt-1 mb-0">Media Settings</h5>
                  <div><router-link class="nav-link sub" to="/media/media-library">Media Library </router-link></div>
                  <div><router-link class="nav-link sub" to="/media/update-thumbnail">Thumbnail Update </router-link>
                  </div>
                  <div><router-link class="nav-link sub" to="/media/media-settings">Media Settings </router-link></div>
                </li>
              </ul>
            </b-collapse>
          </li> -->

        </ul>
      </VuePerfectScrollbar>
    </nav>
  </section>
</template>
<!-- 
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  name: "sidebar",
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      collapses: [{ show: false }, { show: false }, { show: false }],
    };
  },
  methods: {
    collapseAll() {
      var exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        var elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }

    },
  },
  mounted() {
    const body = document.querySelector("body");
    const myArray = document.querySelectorAll(".sidebar .nav-item")
    document.querySelectorAll(".sidebar .nav-item").forEach(function (el) {
      el.addEventListener("click", function () {
      
        myArray.forEach((value, index) => {
        
          if (value.className == "nav-item hover-open") {
            
            value.classList.remove("hover-open");
           
          }

        })
        if (body.classList.contains("sidebar-icon-only")) {
        
          el.classList.add("hover-open");
          setTimeout(() => {
            el.classList.remove("hover-open");
      }, 3000);  
        }
      });

      
    });
  },
  watch: {
    $route() {
      document.querySelector("#sidebar").classList.toggle("active");
    },
  },
};
</script> -->

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  name: 'sidebar',
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      collapses: [
        { show: false },
        { show: false },
        { show: false }
      ]
    }
  },
  methods: {
    collapseAll() {
      var exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        var elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    }
  },
  mounted() {
    const body = document.querySelector('body')
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
        }
      })
    })
  },
  watch: {
    $route() {
      document.querySelector('#sidebar').classList.toggle('active');
    }
  }
}
</script>

<style>
.sidebar .nav .nav-item .nav-link i.menu-icon:before{
  /* background: #fbd9e4;
  border-radius: 50px;
    padding: 3px 3px; */
}
</style>